<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card class="mx-auto px-6 py-2 mb-4">
        <v-card-actions>
          <div>Add Carrier Zone</div>

          <v-spacer></v-spacer>
           <v-tooltip left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <p><b>Carrier Zones</b></p>
              <li>Carrier rates must be submitted against a valid zone reference, if zone does not exist yet it must be created and have mappings assigned to it.</li>
              <li>After creating the zone reference, the files to upload the respective mappings can be found in sorted, or they can also be manually created via excel</li>
         

            </v-tooltip>
          <v-btn icon @click="showAddZoneForm = !showAddZoneForm">
            <v-icon>{{
              showAddZoneForm ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
              
        </v-card-actions>

        <v-expand-transition>
          <div v-show="showAddZoneForm" class="mb-4">
            <v-divider></v-divider>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  class="ml-1 mr-1"
                  outlined
                  dense
                  label="Name (optional)"
                  type="text"
                  v-model.trim="zoneField"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  class="ml-1 mr-1"
                  outlined
                  dense
                  label="Reference"
                  type="text"
                  v-model.trim="zoneReferenceField"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  class="ml-1 mr-1"
                  outlined
                  dense
                  label="Description (optional)"
                  type="text"
                  v-model.trim="zoneDescriptionField"
                ></v-text-field>
              </v-col>
              <v-col cols="12" align="center">
                <v-btn
                  color="primary"
                  outlined
                  :disabled="!zoneReferenceField"
                  @click="insertCarrierBillingZone"
                  >Add Zone
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="12"> Carrier Zone Mapping </v-col>
          <v-col cols="4">
            <v-select
              menu-props="auto"
              v-model="selectedCarrierBillingZone"
              :items="carrierBillingZones"
              item-text="reference"
              return-object
              label="Select Carrier Zone"
              outlined
              dense
              @change="getCarrierBillingZoneMapping"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn color="green" outlined @click="downloadMappingTemplate"
              >Download Zone Mapping Template</v-btn
            >
          </v-col>
          <v-col cols="4">
            <v-file-input
              v-show="
                selectedCarrierBillingZone && selectedCarrierBillingZone.id
              "
              show-size
              counter
              multiple
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="attach_file"
              label="File input"
              @change="handleFileUpload"
            ></v-file-input>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              class="mt-0"
              v-model="overwriteZoneMapping"
              label="Overwrite if zone mapping already exists"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-show="
                selectedCarrierBillingZone && selectedCarrierBillingZone.id
              "
              :disabled="
                !selectedCarrierBillingZone ||
                !selectedCarrierBillingZone.id ||
                zoneMapping.length < 1
              "
              color="primary"
              outlined
              @click="insertCarrierBillingZoneMapping"
              >Save Zone Mapping
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="zoneMapping.length">
            <v-card outlined class="pa-2">
                <v-card-actions>

              <div>Preview of uploaded file</div>   <v-spacer></v-spacer>

          <v-btn icon @click="showPreview = !showPreview">
            <v-icon>{{ showPreview ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
                </v-card-actions>
              <v-expand-transition>
                <div v-show="showPreview" >
                 <v-data-table :headers="currentCarrierZoneMappingHeaders" :items="zoneMapping" :items-per-page="5"></v-data-table>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
           <v-col cols="12" v-if="currentCarrierZoneMapping.length">
            <v-card outlined class="pa-2">
              <div class="pa-2">Current Mappings for Zone: {{ selectedCarrierBillingZone.reference }}</div>
              <v-data-table :headers="currentCarrierZoneMappingHeaders" :items="currentCarrierZoneMapping" :items-per-page="5"></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from "@/services/Carriers.js";
import ExcelMixin from "@/mixins/Excel";

export default {
  mixins: [ExcelMixin],
  data() {
    return {
      validForm: true,
      // Title
      title: "Carrier Zones",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      carrierBillingZones: [],
      selectedCarrierBillingZone: null,

      showPreview: false,
      zoneMapping: [],
      overwriteZoneMapping: false,
      file: null,

      showAddZoneForm: true,
      zoneField: "",
      zoneReferenceField: "",
      zoneDescriptionField: "",

      currentCarrierZoneMapping: [],
      currentCarrierZoneMappingHeaders: [
        { text: 'Country Code', value: 'country_code' },
        { text: 'Post Code Area', value: 'post_code_area' },
        { text: 'Post Code District', value: 'post_code_district' },
        { text: 'Post Code Sector', value: 'post_code_sector' },
        { text: 'Post Code Unit', value: 'post_code_unit' },
        { text: 'Label', value: 'label' },
        { text: 'Description', value: 'description' },
      
      ],

    };
  },

  created() {
    this.getCarrierBillingZones();
  },
  methods: {
    clearFields() {
      this.zoneField = "";
      this.zoneReferenceField = "";
      this.zoneDescriptionField = "";
      this.file = null;
      this.zoneMapping = [];
    },
    async handleFileUpload(ev) {
      const file = ev[0];
      this.file = file;

      if (file) {
        this.extractJson(file)
          .then((jsonSpreadsheet) => {
            this.zoneMapping =
              this.extractMappingsFromSpreadsheet(jsonSpreadsheet);
          })
          .catch((err) => {
            this.snackbar = true;
            this.text = err;
          });
      }
    },
    extractMappingsFromSpreadsheet(spreadsheet) {
      let tempArray = [];

      spreadsheet.forEach((row) => {
        let tempObj = {
          country_code: row.CountryIsoCode,
          post_code_area: row.PostcodeArea,
          post_code_district: row.PostcodeDistrict,
          post_code_sector: row.PostcodeSector,
          post_code_unit: row.PostcodeUnit,
          label: row.Label,
          description: row.Description,
        };
        tempArray.push(tempObj);
      });
      return tempArray;
    },
    getCarrierBillingZones() {
      this.loading = true;
      Carriers.getCarrierBillingZones()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carrierBillingZones = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    insertCarrierBillingZone() {
      if (!this.zoneReferenceField) return false;

      this.loading = true;
      let reqBody = [
        {
          zone: this.zoneField,
          reference: this.zoneReferenceField,
          description: this.zoneDescriptionField,
        },
      ];
      Carriers.insertCarrierBillingZone(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.clearFields();
          this.getCarrierBillingZones();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    getCarrierBillingZoneMapping(){
      this.loading = true;
      this.currentCarrierZoneMapping = []
      Carriers.getCarrierBillingZoneMapping(this.selectedCarrierBillingZone.id)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.currentCarrierZoneMapping = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response ? error.response.data.message : `${error.message}`;
          this.loading = false;
        });
    },
    insertCarrierBillingZoneMapping() {
      if (!this.selectedCarrierBillingZone.id || this.zoneMapping.length < 1)
        return false;
      let confirmMsg = `Are you sure you want to add ${
        this.zoneMapping.length
      } ${this.zoneMapping.length === 1 ? "mapping" : "mappings"}  to zone ${
        this.selectedCarrierBillingZone.reference
      }`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          carrier_billing_zone_id: this.selectedCarrierBillingZone.id,
          mappings: this.zoneMapping,
          overwrite: this.overwriteZoneMapping,
        };
        Carriers.insertCarrierBillingZonesMapping(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;
            this.clearFields();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
    downloadMappingTemplate() {
      let template = {									
        CountryIsoCode: "GB",
        PostcodeArea: "TR",
        PostcodeDistrict: "21",
        PostcodeSector: "",
        PostcodeUnit: "",
        Label: "TR21",
        Description: "Isles of Scilly - St Marys"
      };
      this.generateTemplate(template, "zone_mapping_template");
    },
  },
};
</script>
<style></style>
